import React from 'react'
import Logo from 'components/Logo'

const IndexPage = () => {
  return (
    <div className="max-w-xl mx-auto py-12 flex flex-col items-center px-4">
      <Logo className="h-8 sm:h-10 w-auto" />

      <div className="mt-12 space-y-8 text-center">
        <p className="max-w-sm mx-auto">
          Good news! Lighthouse Metrics got a huge update and is now supporting
          Lighthouse 9.0!
        </p>

        <a
          className="flex mx-auto justify-center items-center bg-blue-900 w-max-content px-4 py-2 rounded text-white"
          href="https://lighthouse-metrics.com?ref=old.lighthouse-metrics.com"
        >
          Take Me to the New Version
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="ml-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </a>
      </div>

      <div className="mt-20 space-y-8 text-center max-w-sm mx-auto">
        <p className="bg-orange-50 px-4 py-2 rounded border-2 border-orange-300 text-orange-800">
          You are currently viewing the old version of Lighthouse Metrics
        </p>

        <p>
          This version{' '}
          <b className="font-semibold">is now in a read-only mode</b> and it{' '}
          <b className="font-semibold">will be removed</b> at the end of the
          year, Dec 31, 2021.
        </p>

        <p>
          Until then, you are still able to view reports that have been created
          under the old version.
        </p>

        <p>
          Not happy with that change? Please reach out!
          <br />
          <a
            href="mailto:feedback@lighthouse-metrics.com"
            className="text-blue-600 font-medium"
          >
            feedback@lighthouse-metrics.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default IndexPage
